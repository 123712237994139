import React, { useState,useEffect, useRef } from 'react'
import "./ContactSection.css";
import personIcon from "../../images/person.png"
import axios from "axios"
// import ReCAPTCHA from "react-google-recaptcha";


function ContactSection(props) {
    const data = props.data;
    console.log(props.selectedService)

    const [formData, setFormData] = useState(props.selectedService ? { services: props.selectedService } : { services: "Financial Reporting Program" });
    const [emailResponse, setEmailResponse] = useState("");
    const [recaptchaToken, setRecaptchaToken] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6Lfj7y0pAAAAABYRdo2Phfxb3HDDMRfty8TScSf-';
        script.addEventListener('load', () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6Lfj7y0pAAAAABYRdo2Phfxb3HDDMRfty8TScSf-', { action: 'submit' }).then((recaptchaToken) => {
                    setRecaptchaToken(recaptchaToken);
                    // You can handle the token here (e.g., store it in a state)
                });
            });
        });
        document.body.appendChild(script);
    }, []);


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const onChange = (token) => {
        // Update the state with the reCAPTCHA token
        setRecaptchaToken(token);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log('Form submitted. reCAPTCHA token:', recaptchaToken);


        if (recaptchaToken) {
            formData.language = "EL";
            console.log(formData);

            axios.post('https://admin.ficon.gr/wp-json/myplugin/v1/scheduleAction', formData)
                .then(response => {
                    setEmailResponse(response.data);
                })
                .catch(error => {
                    console.log(error)
                    setEmailResponse("Παρουσιάστηκε πρόβλημα, δοκιμάστε να επικοινωνήσετε μαζί μας στο τηλέφωνο 696 xxx xxx x");
                    // handle error
                });
        }
    };

    

    return (
        <div id="schedule-form" className="py-16 md:py-32 m-auto text-center px-4 md:px-0 container-cs z-10">
            <div className='flex flex-wrap justify-center'>
                <div className='pr-0 md:pr-8 pb-4 w-full md:w-1/4'>
                    <img src={personIcon} width="155px" />
                </div>
                {!emailResponse && (
                    <div className='w-full md:w-3/4'>
                        <div className='w-full text-left'>
                            <h2>{data.title}</h2>
                            <div className='ss-sub pt-4 pb-8' dangerouslySetInnerHTML={{ __html: data.content }} />
                        </div>

                        <div className='w-full pt-12 md:pt-0 flex flex-wrap justify-center'>

                            <form onSubmit={handleSubmit} className='flex flex-wrap justify-center'>
                                {data.form && data.form.map((field, i) => {
                                    if (field.type == "textarea") {
                                        return (
                                            <div key={`form-field-${i}`} className="form-textareas">
                                                <textarea id={field.value} onChange={handleInputChange} name={field.value} className="form-control" placeholder={field.label} cols="30" rows="6" required />
                                            </div>
                                        )
                                    } else if (field.type == "select") {
                                        return (
                                            <div key={`form-field-${i}`} className="form-inputs">
                                                <select id={field.value} value={formData.services} onChange={handleInputChange} type={field.type} name={field.value} className="form-control" placeholder={field.label} required >
                                                    <option>Επιχειρηματικό Σχέδιο (Business Plan)</option>
                                                    <option>Προϋπολογισμός Λειτουργίας (Budget)</option>
                                                    <option>Financial Reporting Program</option>
                                                    <option>The Growth Program</option>
                                                    <option>The Startup Program</option>
                                                    <option>Ταμειακή Διαχείριση (Cash Flow)</option>
                                                    <option>Ανάλυση & Διαχείριση Κόστους</option>
                                                    <option>Επενδυτικά Προγράμματα</option>
                                                    <option>Αίτηση Δανειοδότησης</option>
                                                </select>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div key={`form-field-${i}`} className="form-inputs">
                                                <input id={field.value} onChange={handleInputChange} type={field.type} name={field.value} className="form-control" placeholder={field.label} required />
                                            </div>
                                        )
                                    }
                                })}

                                {/* <ReCAPTCHA
                                    sitekey="6Lfj7y0pAAAAABYRdo2Phfxb3HDDMRfty8TScSf-"
                                    onChange={onChange} // Define a function to handle the change
                                /> */}
                            </form>

                            <div className='flex justify-start pt-6 md:pt-0 '>
                                <button onClick={handleSubmit} className='main-bttn'>
                                    <span>{data.formCta}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {emailResponse && (
                    <div className="email-responses" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                )}
            </div>
        </div>
    );
}

export default ContactSection;
