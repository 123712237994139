import React, { useState } from 'react'
import transitionBottomImg from "../../images/bottom_wave_02_gray.png"
import transitionBottomImgW from "../../images/Wave_White_bottom_right_shape_trans.png"
function GeneralIntro(props) {
    const data = props.data;
    console.log(data)

    return (
        <div className="about-container">
            <div className='about-parallax container' style={{ backgroundImage: `url("${data?.image?.sourceUrl}")` }}>
                <div className='about-info px-4 md:px-12'>
                    <h1 className='about-h1'>{data?.title ? data?.title : data?.name }</h1>
                    <div className='about-m-editor' dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>

                {/* <div style={{ minHeight: "70vh" }} /> */}
            </div>

            { data.whiteWave
            ?
            <div className='bt_bb_section_bottom_section_coverage_image' style={{zIndex: "10"}}>
                <img src={transitionBottomImgW} />
            </div>
    
            :
            <div className='bt_bb_section_bottom_section_coverage_image' style={{zIndex: "10"}}>
                <img src={transitionBottomImg} />
            </div>
            }
        
        </div>
    );
}

export default GeneralIntro;