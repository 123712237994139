import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Faqs from "../components/Service/Faqs"
import ContactSection from "../components/Home/ContactSection"
import GeneralIntro from "../components/Global/GeneralIntro"

import '@wordpress/block-library/build-style/style.css'

function ServicePageTemplate(props) {
    console.log(props)
    let data = props.data;

    // console.log(data)


    // function constructMetaData(page, currentPage, language) {
    //     let payload = {
    //         title: page.seo.title,
    //         description: page.seo.metaDesc,
    //         canonical: `https://ficon.gr${currentPage}`,
    //         fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
    //         fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
    //         fbUrl: `https://ficon.gr${currentPage}`,
    //         fbType: "website",
    //         locale: language.toLowerCase(),
    //         image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
    //     }
    //     return payload
    // }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
        // locale={props.pageContext.language}
        // metaData={constructMetaData(data.allWpPage.nodes[0], props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>

                <section id="firstSection">
                    <GeneralIntro
                        data={{ title: data.wpService.title, description: "", whiteWave: "true" }}
                    />
                </section>

                <section>
                    <div className="container-cs mx-auto py-16 md:py-32 px-4">
                        {/* <div className="sp-img-container w-full relative m-auto" style={{ maxWidth: "500px" }}>
                            {(data.wpService && data.wpService.featuredImage && data.wpService.featuredImage.node) && (
                                // <GatsbyImage
                                //     image={data.wpService.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                //     alt={data.wpService.featuredImage.node.altText}
                                //     className=" w-full pb-6 sp-f-img" />
                                <img src={data.wpService.featuredImage.node.sourceUrl} alt={data.wpService.featuredImage.node.altText} className="m-auto" />
                            )}
                        </div>

                        <h1 className="service-h1">{data.wpService.title}</h1> */}


                        <div className="service-editor2 editor-styles-wrapper" dangerouslySetInnerHTML={{ __html: data.wpService.content }} />
                    </div>
                </section>

                {(data.wpService.serviceAcf.faqs && data.wpService.serviceAcf.faqs?.title) && (
                    <section>
                        <Faqs
                            data={data.wpService.serviceAcf.faqs}
                        />
                    </section>
                )}

                <section className="relative">
                    <ContactSection
                        data={data.wpTemplate.globalInformation?.contactForm}
                        selectedService={data.wpService.title}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default ServicePageTemplate

export const pageQuery = graphql`query GET_SERVICE_PAGE($id: String, $templateId: String) {
    wpService(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        content
        excerpt
        featuredImage {
            node{
                altText
                sourceUrl
            }
        }
        serviceAcf{
            serviceIcon{
                sourceUrl
                altText
                localFile {
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                    }
                }  
            }
            faqs {
                title
                faqs {
                    question
                    answer
                }
            }
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
